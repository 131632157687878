import React, { Fragment, useEffect } from 'react';
import NavBar from '../../components/nav/NavBar.js';
import Banner from '../../components/banner/Banner.js';
import PageImage from '../../components/page-image/PageImage.js';
import TermsContent from '../../components/terms-content/TermsContent.js';
import SplashImage from '../../components/splash-image/SplashImage.js';
import Footer from '../../components/footer/Footer.js';
import './Terms.css';

function Terms() {

    useEffect(() => {
        document.title = "R.E.M - Terms & Conditions"
      }, []);

    return (
        <Fragment>
            <div className="fixed-header">
                <div className="nav-bar-container">
                    <NavBar />
                </div>
                <div className="banner-container">
                    <Banner />
                </div>
            </div>
            <div className="fixed-header-content">
                <div className="page-image-container">
                    <PageImage imageText={"Terms & Conditions"}/>
                </div>
                <div className="terms-content-container">
                    <TermsContent />
                </div>
                <div className="splash-image-container">
                    <SplashImage />
                </div>
                <div className="footer-container">
                    <Footer />
                </div>
            </div>
        </Fragment>
    );

}

export default Terms;
import React, { Fragment } from 'react';
import location from '../../assets/images/location.png';
import phone from '../../assets/images/phone-blue.png';
import email from '../../assets/images/email.png';
import clock from '../../assets/images/clock.png';
import './ContactContent.css';

function ContactContent() {

    return (
        <Fragment>
            <div className="contact-content-body-container" data-aos="zoom-in-up">
                <p className="contact-content-section">
                    <img className="contact-location-icon" src={location} alt="Location" />
                Invergyle Dr, Glasgow G52 2BL</p>
                <p className="contact-content-section"><a className="contact-link" href="tel: 07309764549">
                    <img className="contact-phone-icon" src={phone} alt="Telephone" />
                07309 764 549</a></p>
                <p className="contact-content-section"><a className="contact-link" href="mailto: info@remlocksmiths.co.uk">
                    <img className="contact-email-icon" src={email} alt="Email" />
                    info@remlocksmiths.co.uk</a></p>
                <p className="contact-content-section">
                    <img className="contact-clock-icon" src={clock} alt="Open Times" />
                Open 24 hours a day, 7 days a week!</p>
           </div>
        </Fragment>
    );

}

export default ContactContent;
import React, { Fragment } from 'react';
import './ServicesContent.css';

function ServicesContent() {

    return (
        <Fragment>
            <div className="content-body-container">
                <p className="services-content-header" data-aos="zoom-out-left"><b>Emergency Locksmith</b></p>
                <p className="services-content-body" data-aos="zoom-out-left">Getting locked out of your home or business can be frustrating. It has happened to us all at some point. We can help with that! R.E.M Locksmiths are a local, independent and reliable locksmiths that only send fully qualified Locksmith technicians. Where possible, we will gain access to your property via non-destructive methods, leaving minimal damage - if any! R.E.M Locksmiths are available 24/7, 365 days a year - our rapid emergency response team is here to help - just give us call.</p>
                <p className="services-content-header" data-aos="zoom-out-right"><b>Residential Locksmith</b></p>
                <p className="services-content-body" data-aos="zoom-out-right">With years of experience, all of our technicians are highly skilled and are able to carry out a wide variety of residential lockmsith services. Whether you need emergency access to your home or need new locks installed, R.E.M Locksmiths have the best Locksmith for your needs.</p>
                <p className="services-content-header" data-aos="zoom-out-left"><b>Commercial Locksmith</b></p>
                <p className="services-content-body" data-aos="zoom-out-left">R.E.M Locksmiths offer a wide range of commercial locksmith services designed to enhance the security and safety of your company. Employee thefts contribute to almost 50% of all inventory loss in the United Kingdom - nearly £15 billion. A high percentage of this is due misplaced keys, poor lock secuity and ex-employees not returning keys. Is your business vunerable? Act now for peace of mind!</p>
           </div>
        </Fragment>
    );

}

export default ServicesContent;
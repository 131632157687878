import React, { Fragment } from 'react';
import './NavList.css';

function NavList() {

    return (
        <Fragment>
            <ul>
                <li><a className="nav-list-link nav-list-button" href="/contact">Contact</a></li>
                <div className="nav-list-wrapper">
                    <div className="nav-list-link-hover">
                        <li><a className="nav-list-link" href="/services">Services</a></li>
                    </div>
                    <div className="nav-list-link-hover">
                        <li><a className="nav-list-link " href="/">Home</a></li>
                    </div>
                </div>
            </ul>
        </Fragment>
    );

}

export default NavList;
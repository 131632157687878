import React, { Fragment } from 'react';
import {slide as Menu} from 'react-burger-menu'
import './Burger.css';

function Burger() {

    return (
        <Fragment>
            <Menu right width={ '250px' }>
                <a className="menu-item" href="/">Home</a>
                <a className="menu-item" href="/services">Services</a>
                <a className="menu-item" href="/contact">Contact</a>
                <a className="menu-item" href="/faq">FAQ</a>
                <a className="menu-item" href="/terms">Terms</a>
            </Menu>
        </Fragment>
    );

}

export default Burger;
import React, { Fragment } from 'react';
import Logo from '../nav/logo/Logo';
import phone from '../../assets/images/phone.png';
import './LogoBanner.css';

function LogoBanner() {

    return (
        <Fragment>
            <div className="logo-banner-outer" data-aos="zoom-in-down">
                <div className="logo-banner-inner">
                    <center>
                        <Logo styleName={"rem-logo-logo-banner"} />
                        <div className="logo-banner-tel-container">
                                <img className="logo-banner-telephone-icon" src={phone} alt="Telephone" />
                                <a className="logo-banner-telephone" href="tel: 07309764549">07309 764 549</a>
                        </div>
                    </center>
                </div>
            </div>
        </Fragment>
    );

}

export default LogoBanner;
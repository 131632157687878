import React, { Fragment, useEffect } from 'react';
import NavBar from '../../components/nav/NavBar.js';
import Banner from '../../components/banner/Banner.js';
import PageImage from '../../components/page-image/PageImage.js';
import FaqContent from '../../components/faq-content/FaqContent.js';
import InfoBanner from '../../components/info-banner/InfoBanner.js';
import Brands from '../../components/brands/Brands.js';
import Reviews from '../../components/reviews/Reviews.js';
import SplashImage from '../../components/splash-image/SplashImage.js';
import Footer from '../../components/footer/Footer.js';
import './Faq.css';

function Faq() {

    useEffect(() => {
        document.title = "R.E.M - FAQ"
      }, []);

    return (
        <Fragment>
            <div className="fixed-header">
                <div className="nav-bar-container">
                    <NavBar />
                </div>
                <div className="banner-container">
                    <Banner />
                </div>
            </div>
            <div className="fixed-header-content">
                <div className="page-image-container">
                    <PageImage imageText={"FAQ"}/>
                </div>
                <div className="faq-content-container">
                    <FaqContent />
                </div>
                <div className="info-banner-container">
                        <InfoBanner />
                </div>
                <div className="brands-reviews-container">
                    <div className="brands-container">
                            <Brands />
                    </div>
                    <div className="reviews-container">
                            <Reviews />
                    </div>
                </div>
                <div className="splash-image-container">
                        <SplashImage />
                </div>
                <div className="footer-container">
                        <Footer />
                </div>
            </div>
        </Fragment>
    );

}

export default Faq;
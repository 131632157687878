import React, { Fragment } from 'react';
import unlock from '../../assets/images/unlock.png';
import './ServicesList.css';

function ServicesList() {

    return (
        <Fragment>
            <div className="services-banner-outer" data-aos="zoom-out-up">
                <div className="services-banner-inner">
                        <p className="service-list-item"><img className="padlock-icon" src={unlock} alt="Padlock" /> Lock Installation</p>
                        <p className="service-list-item"><img className="padlock-icon" src={unlock} alt="Padlock" /> Key Replacement</p>
                        <p className="service-list-item"><img className="padlock-icon" src={unlock} alt="Padlock" /> Lock Repair</p>
                        <p className="service-list-item"><img className="padlock-icon" src={unlock} alt="Padlock" /> Lock Change</p>
                        <p className="service-list-item"><img className="padlock-icon" src={unlock} alt="Padlock" /> Lock Upgrade</p>
                        <p className="service-list-item"><img className="padlock-icon" src={unlock} alt="Padlock" /> Emergency Lockout</p>
                        <p className="service-list-item"><img className="padlock-icon" src={unlock} alt="Padlock" /> Lock Opening</p>
                        <p className="service-list-item"><img className="padlock-icon" src={unlock} alt="Padlock" /> Panic Bars</p>
                        <p className="service-list-item"><img className="padlock-icon" src={unlock} alt="Padlock" /> Letter-box Replacement</p>
                        <p className="service-list-item"><img className="padlock-icon" src={unlock} alt="Padlock" /> Key Duplication</p>
                        <p className="service-list-item"><img className="padlock-icon" src={unlock} alt="Padlock" /> Mechanical Locks</p>
                        <p className="service-list-item"><img className="padlock-icon" src={unlock} alt="Padlock" /> Push/Pull Paddles</p>
                </div>
            </div>
        </Fragment>
    );

}

export default ServicesList;
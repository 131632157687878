import React, {Fragment} from 'react';
import pageImage from '../../assets/images/page-image.jpg';
import './PageImage.css';

const PageImage = ({imageText}) => {

    return (
        <Fragment>
            <div className="page-image-text-container">
                <img className="page-image" src={pageImage} alt="House in Glasgow" />
                <div className="overall-page-text-container">
                    <div className="page-text-container">
                        <p className="page-text">{imageText}</p>
                    </div>
                </div>
            </div>
        </Fragment>
    );

}

export default PageImage;
import React, { Fragment } from 'react';
import key from '../../assets/images/key.png';
import clock from '../../assets/images/clock.png';
import money from '../../assets/images/money.png';
import './InfoBanner.css';

function InfoBanner() {

    return (
        <Fragment>
            <div className="info-banner-overall-container">
                <div className="info-banner-section">
                    <center>
                        <img className="info-banner-icon" src={key} alt="Key" ></img>
                        <p className="info-banner-section-header">Professional</p>
                        <p className="info-banner-section-body">Our trustworthy and experienced Glasgow locksmith technicians are professionally trained to handle any job - big or small!</p>
                    </center>
                </div>
                <div className="info-banner-section">
                    <center>
                        <img className="info-banner-icon" src={clock} alt="Key" ></img>
                        <p className="info-banner-section-header">24/7</p>
                        <p className="info-banner-section-body">We provide fast and reliable emergency locksmith services around the clock, 7 days a week - we won't let you down!</p>
                    </center>
                </div>
                <div className="info-banner-section">
                    <center>
                        <img className="info-banner-icon" src={money} alt="Key" ></img>
                        <p className="info-banner-section-header">Affordable</p>
                        <p className="info-banner-section-body">We strive to provide low-cost, competitive locksmith prices. Low prices mean returning customers!</p>
                    </center>
                </div>
            </div>
        </Fragment>
    );

}

export default InfoBanner;
import React, {Fragment} from 'react';
import logo from '../../../assets/images/rem-logo.png';
import './Logo.css';

function Logo({styleName}) {

    return (
        <Fragment>
            <div className="logo-container">
                <a href="/"> 
                    <img className={styleName} src={logo} alt="REM Locksmiths logo" />
                </a>
            </div>
        </Fragment>
    );

}

export default Logo;
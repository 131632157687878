import React, { Fragment, useEffect } from 'react';
import NavBar from '../../components/nav/NavBar.js';
import Banner from '../../components/banner/Banner.js';
import PageImage from '../../components/page-image/PageImage.js';
import ContactContent from '../../components/contact-content/ContactContent.js';
import LogoBanner from '../../components/logo-banner/LogoBanner.js';
import InfoBanner from '../../components/info-banner/InfoBanner.js';
import Brands from '../../components/brands/Brands.js';
import Reviews from '../../components/reviews/Reviews.js';
import SplashImage from '../../components/splash-image/SplashImage.js';
import Footer from '../../components/footer/Footer.js';
import './Contact.css';

function Contact() {

    useEffect(() => {
        document.title = "R.E.M - Contact"
      }, []);

    return (
        <Fragment>
            <div className="fixed-header">
                <div className="nav-bar-container">
                    <NavBar />
                </div>
                <div className="banner-container">
                    <Banner />
                </div>
            </div>
            <div className="fixed-header-content">
                <div className="page-image-container">
                    <PageImage imageText={"Contact"}/>
                </div>
                <div className="content-logo-banner-container">
                    <div className="content-container">
                        <ContactContent />
                </div>
                <div className="logo-banner-container">
                    <LogoBanner />
                </div>
                </div>
                <div className="info-banner-container">
                        <InfoBanner />
                </div>
                <div className="brands-reviews-container">
                    <div className="brands-container">
                            <Brands />
                    </div>
                    <div className="reviews-container">
                            <Reviews />
                    </div>
                </div>
                <div className="splash-image-container">
                        <SplashImage />
                </div>
                <div className="footer-container">
                        <Footer />
                </div>
            </div>
        </Fragment>
    );

}

export default Contact;
import React, {Fragment} from 'react';
import Burger from './burger/Burger.js';
import Logo from './logo/Logo.js';
import NavList from './nav-list/NavList.js';
import './NavBar.css';

function NavBar() {

    return (
        <Fragment>
            <div className="nav-logo-container">
                <Logo styleName={"rem-logo-banner"}/>
            </div>
            <div className="nav-list-container">   
                <NavList />
            </div>
            <div className="burger-container">
                <Burger  />
            </div>
        </Fragment>
    );

}

export default NavBar;
import React, {useEffect} from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Home from './views/home/Home.js'
import Services from './views/services/Services.js';
import Contact from './views/contact/Contact.js';
import Terms from './views/terms/Terms.js';
import Faq from './views/faq/Faq.js';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {

  useEffect(() => {
    AOS.init();
  });

    return (
        <Router>
            <Switch>
                <Route path="/services">
                  <Services />
                </Route>
                <Route path="/contact">
                  <Contact />
                </Route>
                <Route path="/terms">
                  <Terms />
                </Route>
                <Route path="/faq">
                  <Faq />
                </Route>
                <Route path="/">
                    <Home />
                </Route>
                <Route>
                    <Home />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
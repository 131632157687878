import React, {Fragment} from 'react';
import hero from '../../assets/images/hero-image.jpg';
import phone from '../../assets/images/phone.png';
import './HeroImage.css';

function HeroImage() {

    return (
        <Fragment>
            <div className="hero-image-text-container">
                <img className="hero-image" src={hero} alt="House in Glasgow" />
                <div className="overall-hero-text-container">
                    <div className="hero-text-container hero-top">
                        <p className="hero-text-top">24 Hours Emergency Locksmith</p>
                    </div>
                    <div className="hero-text-container hero-bottom">
                        <p className="hero-text-bottom">CALL US NOW
                            <span className="hero-tel-container">
                                <img className="hero-telephone-icon" src={phone} alt="Telephone" />
                                <a className="hero-telephone" href="tel: 07309764549">07309 764 549</a>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </Fragment>
    );

}

export default HeroImage;
import React, { Fragment, useState } from 'react';
import stars from '../../assets/images/stars.png';
import quote from '../../assets/images/quote.png';
import './Reviews.css';

function Reviews() {

    const [showReview1, setShowReview1] = useState(true);
    const [bgColor1, setBgColor1] = useState("#d7d7d7");
    const [showReview2, setShowReview2] = useState(false);
    const [bgColor2, setBgColor2] = useState("#3b5998");
    const [showReview3, setShowReview3] = useState(false);
    const [bgColor3, setBgColor3] = useState("#3b5998");

    function changeToReview1() {
        setShowReview1(true);
        setShowReview2(false);
        setShowReview3(false);
        setBgColor1("#d7d7d7");
        setBgColor2("#3b5998");
        setBgColor3("#3b5998");
    }

    function changeToReview2() {
        setShowReview1(false);
        setShowReview2(true);
        setShowReview3(false);
        setBgColor1("#3b5998");
        setBgColor2("#d7d7d7");
        setBgColor3("#3b5998");
    }

    function changeToReview3() {
        setShowReview1(false);
        setShowReview2(false);
        setShowReview3(true);
        setBgColor1("#3b5998");
        setBgColor2("#3b5998");
        setBgColor3("#d7d7d7");
    }

    if (showReview1) {
        var review1 =
        <div className="review">
            <div className="quote-image-container">
                <img className="quote-icon" src={quote} alt="Double Quotes" />
            </div>
            <div className="quote-container">
                <img className="five-stars" src={stars} alt="Five Star Review" />
                <div>
                    <p className="review-content"><i>Mark attended on a Saturday morning at no extra charge, removed and replaced my broken front door locks (Double glazed doors). He came when he said he would, was quick, clean and great value for money. I would definitely use again and recommend his work to anyone looking for a locksmith and not wanting ‘ripped off’.</i></p>
                    <p className="review-content"><b>Positive</b>: Professionalism, Punctuality, Quality, Value</p>
                    <p className="reviewer"><b>Ruth Dorman</b></p>
                </div>
            </div>
        </div>
      }

      if (showReview2) {
        var review2 =
        <div className="review">
            <div className="quote-image-container">
                <img className="quote-icon" src={quote} alt="Double Quotes" />
            </div>
            <div className="quote-container">
                <img className="five-stars" src={stars} alt="Five Star Review" />
                <div>
                    <p className="review-content"><i>Friendly, reliable and very knowledgeable! Great service would highly recommend!</i></p>
                    <p className="review-content"><b>Positive</b>: Professionalism, Punctuality, Quality, Value</p>
                    <p className="reviewer"><b>Ross Ferguson</b></p>
                </div>
            </div>
        </div>
      }

      if (showReview3) {
        var review3 =
        <div className="review">
            <div className="quote-image-container">
                <img className="quote-icon" src={quote} alt="Double Quotes" />
            </div>
            <div className="quote-container">
                <img className="five-stars" src={stars} alt="Five Star Review" />
                <div>
                    <p className="review-content"><i>Trust worthy and very professional. Want your lock fixed call this man</i></p>
                    <p className="reviewer"><b>David Adam</b></p>
                </div>
            </div>
        </div>
      }

    return (
        <Fragment>
            <center data-aos="flip-left">
                <p className="reviews-title">Reviews</p>
                    {review1}
                    {review2}
                    {review3}
                    <div className="review-button-container">
                        <div style={{backgroundColor: bgColor3}} className="review-button" onClick={changeToReview3}></div>
                        <div style={{backgroundColor: bgColor2}} className="review-button" onClick={changeToReview2}></div>
                        <div style={{backgroundColor: bgColor1}} className="review-button" onClick={changeToReview1}></div>
                    </div>
            </center>
        </Fragment>
    );

}

export default Reviews;
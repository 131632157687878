import React, {Fragment} from 'react';
import phone from '../../assets/images/phone.png';
import './Banner.css';

function Banner() {

    return (
        <Fragment>
            <div className="banner">
                <center>
                    <p className="banner-content">GLASGOW 24/7
                        <span>
                            <img className="banner-telephone-icon" src={phone} alt="Telephone"></img>
                            <a className="banner-telephone" href="tel: 07309764549">07309 764 549</a>
                        </span>
                    </p>
                </center>
            </div>
        </Fragment>
    );

}

export default Banner;
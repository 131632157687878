import React, { Fragment } from 'react';
import './TermsContent.css';

function TermsContent() {

    return (
        <Fragment>
            <div className="content-body-container">
                <p className="content-body">These are the terms and conditions (“Contract”) on with R.E.M Locksmiths (“We”/“Us”/“Our”) supply locksmith (“Services”) to the customer (“You”/“Your”). Our terms and conditions apply within Scotland, UK.</p>
                <p className="content-body">All locksmith work, services, and invoices, provided by R.E.M Locksmiths including third party contract services are subject to these terms and conditions. R.E.M Locksmiths have the right to change, update and amend these terms and conditions at any time without notice.</p>
                <p className="content-body">Please ensure that You read these terms and conditions carefully as they are binding on You and Us, and a contract will come into existence when you accept an estimate or quote for the Services that We provide to You. If You have any questions or any of these terms and conditions are not acceptable to You, please contact Us via Email.</p>
                <p className="content-body">R.E.M Locksmiths reserves the right (in our sole and absolute discretion) to refuse to provide locksmith services to You (including installation of Your materials).</p>
                <p className="content-body">OUR LIABILITY TO YOU: We will NOT be liable to You for loss or damage due to the Contract unless We cause death or injury through Our negligence. If You are a consumer, We will Not be responsible under this Contract for any loss or damage which relates to any business of yours. Nothing in these Terms will affect Your right to bring legal action. R.E.M Locksmiths will accept No claim or damage to any door or Glass frame unless it is a direct result of negligence by the Locksmith. providing that the Locksmith explained the risks to the client, then this or any other repair / replacement cost will have to be borne by the client.</p>
                <p className="content-body">All parts supplied by Us and all materials used in carrying out the Services shall remain Our property until payment is received from You in full. We may use independent contractors (“Other Contractors”) to provide Services to you. Other Contractors have no authority to insure liability on behalf of or to act as agent for us. All parts and all materials used by Us in carrying out the Services are purchased at registered UK suppliers.</p>
                <p className="content-body">Limitations and exclusions of liability:</p>
                <p className="content-body">10.1 Nothing in these terms and conditions will: (a) limit or exclude any liability for death or personal injury resulting from negligence; (b) limit or exclude any liability for fraud or fraudulent misrepresentation; (c) limit any liabilities in any way that is not permitted under applicable law; or (d) exclude any liabilities that may not be excluded under applicable law.</p>
                <p className="content-body">10.2 The limitations and exclusions of liability set out in this Section 12 and elsewhere in these terms and conditions: (a) are subject to Section 10.1; and (b) govern all liabilities arising under these terms and conditions or relating to the subject matter of these terms and conditions, including liabilities arising in contract, in tort (including negligence) and for breach of statutory duty, except to the extent expressly provided otherwise in these terms and conditions.</p>
                <p className="content-body">10.3  To the extent that our website and the information and services on our website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
                <p className="content-body">10.4  We will not be liable to you in respect of any losses arising out of any event or events beyond our reasonable control.</p>
                <p className="content-body">10.5  We will not be liable to you in respect of any business losses, including (without limitation) loss of or damage to profits, income, revenue, use, production, anticipated savings, business, contracts, commercial opportunities or goodwill.</p>
                <p className="content-body">10.6  We will not be liable to you in respect of any loss or corruption of any data, database or software.</p>
                <p className="content-body">10.7  We will not be liable to you in respect of any special, indirect or consequential loss or damage.</p>
                <p className="content-body">10.8  You accept that we have an interest in limiting the personal liability of our officers and employees and, having regard to that interest, you acknowledge that we are a limited liability entity; you agree that you will not bring any claim personally against our officers or employees in respect of any losses you suffer in connection with the website or these terms and conditions (this will not, of course, limit or exclude the liability of the limited liability entity itself for the acts and omissions of our officers and employees).</p>
                <p className="content-body">YOUR PERSONAL INFORMATION AND HOW WE MAY USE IT: We may request certain information from You prior to commencing Services, for example, proof of Your identity and/or proof that You are entitled to authorize the carrying out of the Services. In the event that You are unable to unwilling to provide such information to Us, We may refuse to provide Services to You. If You are a tenant You will need Your landlord’s permission to allow Us to carry out the work – to is Your responsibility to obtain such information.</p>
                <p className="content-body">We will use the personal information You provide to Us to: (i) provide the Services; (ii) process Your payment for such Services and (iii) inform You about similar products or services that We provide, but You may stop receiving these at any time by contacting Us. You agree that We may pass Your personal information to credit reference agencies and that they may keep a record of any search that they do. We will not give Your personal data to any other third party. Phone calls may be recorded for quality and training purposes.</p>
                <p className="content-body">PRICE AND PAYMENT: The price of our Services will be set out on the invoice overleaf alongside the box marked “Total”. Such price do not include VAT or otherwise stated. The price of Our Services will include labour and any materials used in connection with the provision of the Services. Pricing on the website (including Services and material) refers to working hours and can be a subject to change on site depending on the time, service area, work load and stock price of materials. Scheduled (booked) Services can be a subject to 20% of booking deposit when the quote exceeds the total amount of 200 GBP. Any estimate or quote which We provide to You in writing or orally shall be capable of amendment by Us in the event (i) that We determine additional labour time and/or materials are required in order to complete the Services which were not anticipated by Us at the time the original estimate was provided to You or (ii) of a manifest error in respect of the original estimate. We DO NOT provide discount for not meeting the estimates (time of arrival, labour time and/or price of materials). R.E.M Locksmiths accepts Cash or Bank Transfer. If You are paying by Credit card or debit card you will be charged a commission of 10%.</p>
                <p className="content-body">TELEPHONE CALLS: Telephone​​ calls between​​ the​​ supplier​​ and​​ the​​ customer​​ may​​ be​​r ecorded​​ for​​ educational​​ or​​legal purposes.​​ The​​ customer​​ agrees​​ to​​ this.</p>
                <p className="content-body">CANCELLATION: When R.E.M Locksmiths schedules a job in for You, the job is assigned to a locksmith within Our company or Other Contractors in the matter of minutes, therefore if the job is cancelled or cancelled upon arriving at the location at which You requested the Services to be performed, We or Other Contractors shall be entitled to charge You. If you cancel job before Our locksmith or Other Contractors arrive at Your location, You shall be obliged to pay a cancellation fee of 75% of the agreed amount quoted. Cancellation after Our locksmith or Other Contractors arrives at Your location equals to the full agreed amount quoted. Cancellation charges are payable on the day of cancellation. Once We’ve begun to provide the Services, You may cancel the contract for our Services, provided that You shall remain liable for the full amount of the price set out on the invoice overleaf. If You are to re-schedule the agreed job rather than cancel, for a later date no longer than 14 working days ahead, and giving at-least 24 hours notice, You will not incur any additional fee. Scheduled jobs may only be re-scheduled one time.</p>
                <p className="content-body">IF THERE IS A PROBLEM WITH THE SERVICES: In the unlikely event that there is any defect with the locks supplied and fitted by Us and such defect manifests itself in the twelve (12) month period immediately following completion of the Services, please tell Us as soon as reasonably possible and give Us a reasonable opportunity to inspect and repair such defect or to replace any defective lock as appropriate. In the event that a defect is reported to Us in the twelve (12) month period following completion of the Services and upon our inspection of the matter such defect is found to be genuine (and not the result of deliberate or negligent damage) then We will use every effort to repair or fix the defect as soon as reasonably possible (during working hours if not an emergency). You will not have to pay for Us to repair or fix a defect in the Services under this section. If this defect is not a cause of our Services We have the right to charge the full amount for repair</p>
                <p className="content-body">LEGAL CONDITIONS: The Contract is governed by Scots law. You cannot transfer the legal rights to this Contract without Our written permission. We may transfer any part of this Contract but Your rights and obligations under this Contract will remain unaffected. If We transfer any of the rights and obligations under this Contract to another company the expression “We” or “Our” will include that other company for the purposes of this Contract. If We need to serve a notice on You under this Contract, We will send it to the address set out on Your invoice. You must serve any notices on Us at the address shown below. If any term of this Contract is held by the courts or any other legal or regulatory body to be invalid or unenforceable, the rest of the terms of the Contract shall not be affected by such invalidity or unenforceability.</p>
                <p className="content-body">If You breach any contract between Us and We fail to enforce the provisions of the contract, Our failure or silence should not be understood by You that We are giving up on Our rights and remedies. If either We or You do give up on Our rights and remedies on one occasion, that does not mean that We or You are doing so in respect of any other rights or remedies We may have.</p>
                <p className="content-body">ABOUT US: If You want to ask Us anything about this document or the Services We are performing for You or if You want to make a complaint, please contact us via Our website. You can contact R.E.M Locksmiths by e-mailing Us at <a className="footer-link" href="email: r.e.m.locksmiths@outlook.com">r.e.m.locksmiths@outlook.com</a> within 24 hours of the issues arising.</p>
                <p className="content-body"></p>
           </div>
        </Fragment>
    );

}

export default TermsContent;
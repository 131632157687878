import React, { Fragment } from 'react';
import NavBar from '../../components/nav/NavBar.js';
import Banner from '../../components/banner/Banner.js';
import Content from '../../components/content/Content.js';
import HeroImage from '../../components/hero-image/HeroImage.js';
import LogoBanner from '../../components/logo-banner/LogoBanner.js';
import InfoBanner from '../../components/info-banner/InfoBanner.js';
import Brands from '../../components/brands/Brands.js';
import Reviews from '../../components/reviews/Reviews.js';
import SplashImage from '../../components/splash-image/SplashImage.js';
import Footer from '../../components/footer/Footer.js';
import './Home.css';

function Home() {

    return (
        <Fragment>
            <div className="fixed-header">
                <div className="nav-bar-container">
                    <NavBar />
                </div>
                <div className="banner-container">
                    <Banner />
                </div>
            </div>
            <div className="fixed-header-content">
                <div className="hero-image-container">
                    <HeroImage />
                </div>
                <div className="content-logo-banner-container">
                    <div className="content-container">
                        <Content />
                    </div>
                    <div data-aos="fade-down" className="logo-banner-container">
                        <LogoBanner />
                    </div>
                </div>
                <div className="info-banner-container">
                        <InfoBanner />
                </div>
                <div className="brands-reviews-container">
                    <div className="brands-container">
                            <Brands />
                    </div>
                    <div data-aos="flip-right" className="reviews-container">
                            <Reviews />
                    </div>
                </div>
                <div className="splash-image-container">
                        <SplashImage />
                </div>
                <div className="footer-container">
                        <Footer />
                </div>
            </div>
        </Fragment>
    );

}

export default Home;
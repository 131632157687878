import React, { Fragment } from 'react';
import './FaqContent.css';

function FaqContent() {

    return (
        <Fragment>
            <div className="content-body-container">
                <p className="faq-content-header" data-aos="flip-up"><b>When are you available?</b></p>
                <p className="faq-content-body" data-aos="flip-down">We are available 24/7 - 365 days a year!</p>
                <p className="faq-content-header" data-aos="flip-up"><b>Do you repair car locks?</b></p>
                <p className="faq-content-body" data-aos="flip-down">We do not do cars - please call an Auto Locksmith.</p>
                <p className="faq-content-header" data-aos="flip-up"><b>Will you damage my locks/door?</b></p>
                <p className="faq-content-body" data-aos="flip-down">Where possible, our Locksmiths will gain access using non-destructive entry methods, however, there are some locks that are designed to prevent this. Additionally, faulty locks can’t always be bypassed! In both cases, destructive methods will be required.</p>
                <p className="faq-content-header" data-aos="flip-up"><b>Do you charge for call-outs?</b></p>
                <p className="faq-content-body" data-aos="flip-down">No! We only charge for labour and materials.</p>
                <p className="faq-content-header" data-aos="flip-up"><b>What areas do you cover?</b></p>
                <p className="faq-content-body" data-aos="flip-down">Glasgow and surrounding areas</p>
                <p className="faq-content-header" data-aos="flip-up"><b>Do you charge VAT on labour costs?</b></p>
                <p className="faq-content-body" data-aos="flip-down">No VAT is payable on labour costs - we are not VAT registered.</p>
           </div>
        </Fragment>
    );

}

export default FaqContent;
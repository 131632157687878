import React, {Fragment} from 'react';
import splash from '../../assets/images/splash.png';
import phone from '../../assets/images/phone-blue.png';
import './SplashImage.css';

function SplashImage() {

    return (
        <Fragment>
            <div className="splash-image-text-container">
                <img className="splash-image" src={splash} alt="House in Glasgow" />
                <div className="overall-splash-text-container">
                    <div className="splash-top">
                        <p className="splash-text-top"><span className="hide-text">Broken lock?</span> Lost your keys?</p>
                    </div>
                    <div className="splash-bottom">
                        <p className="splash-text-bottom">We're here to help</p>
                    </div>
                </div>
                <div className="splash-tel-container">
                        <img className="splash-telephone-icon" src={phone} alt="Telephone" />
                        <a className="splash-telephone" href="tel: 07309764549">07309 764 549</a>
                </div>
            </div>
        </Fragment>
    );

}

export default SplashImage;
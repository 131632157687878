import React, {Fragment} from 'react';
import brands from '../../assets/images/brands.jpg';
import './Brands.css';

function Brands() {

    return (
        <Fragment>
            <center data-aos="flip-right">
                <p className="brands-title">Brands</p>
                <img className="brands-image" src={brands} alt="Locksmith Brands" />
            </center>
        </Fragment>
    );

}

export default Brands;
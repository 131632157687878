import React, { Fragment } from 'react';
import './Content.css';

function Content() {

    return (
        <Fragment>
            <div className="content-body-container">
                <p className="content-header" data-aos="fade-up">LOCKED OUT?</p>
                <p className="content-body" data-aos="fade-right"><span className="bold-blue">R.E.M Locksmiths</span> offer a vast selection of locksmith <a className="content-services-link" href="/services"><b>services</b></a> in and around Glasgow. We are an independent and local locksmiths, trained & certified by the <span className="bold-blue">Master Locksmith Association</span>. We take pride in offering excellent quality service and customer satisfaction and have developed a reputation for providing our services in a quick and professional manner in Glasgow.</p>
                <p className="content-body" data-aos="fade-left">If you require locksmith services then you have come to the right place! <span className="bold-blue">R.E.M Locksmiths</span> offer high quality and affordable locksmith services in Glasgow. We provide reliable <span className="bold-blue">residential</span> or <span className="bold-blue">commercial</span> locksmith services.</p>
           </div>
        </Fragment>
    );

}

export default Content;
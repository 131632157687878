import React, {Fragment} from 'react';
import location from '../../assets/images/location.png';
import phone from '../../assets/images/phone-blue.png';
import email from '../../assets/images/email.png';
import clock from '../../assets/images/clock.png';
import Logo from '../nav/logo/Logo.js';
import './Footer.css';

function Footer() {

    return (
        <Fragment>
            <div className="overall-footer-container">
            <div className="footer-sub-container">
                    <div className="footer-content2">
                        <p className="footer-content-section-header">Contact</p>
                        <hr />
                        <p className="footer-content-section">
                            <img className="location-icon" src={location} alt="Location" />
                        Invergyle Dr, Glasgow G52 2BL</p>
                        <p className="footer-content-section"><a className="footer-link" href="tel: 07309764549">
                            <img className="phone-icon" src={phone} alt="Telephone" />
                        07309 764 549</a></p>
                        <p className="footer-content-section"><a className="footer-link" href="mailto: info@remlocksmiths.co.uk">
                            <img className="email-icon" src={email} alt="Email" />
                            info@remlocksmiths.co.uk</a></p>
                        <p className="footer-content-section">
                            <img className="clock-icon" src={clock} alt="Open Times" />
                        24hrs a day - 7 days a week!</p>
                    </div>
                </div>
                <div className="footer-sub-container">
                    <div className="footer-content1">
                        <p className="footer-content-section-header">Navigation</p>
                        <hr />
                        <a className="footer-sitemap-link" href="/">Home</a>
                        <br />
                        <a className="footer-sitemap-link" href="/services">Services</a>
                        <br />
                        <a className="footer-sitemap-link" href="/contact">Contact</a>
                        <br />
                        <a className="footer-sitemap-link" href="/faq">FAQ</a>
                        <br />
                        <a className="footer-sitemap-link" href="/terms">Terms</a>
                    </div>
                </div>
                <div className="footer-logo-container">
                    <Logo styleName={"footer-logo"}/>
                </div>
            </div>
        </Fragment>
    );

}

export default Footer;